export default [
  {english: 'abide by',
  translation: 'zich houden aan'},
  {english: 'accrued',
  translation: 'opgebouwd'},
  {english: 'adage',
  translation: 'gezegde'},
  {english: 'advent',
  translation: 'komst'},
  {english: 'adversary',
  translation: 'tegenstander'},
  {english: 'ailing',
  translation: 'noodlijdend'},
  {english: 'alluded',
  translation: 'gezinspeeld'},
  {english: 'arduous',
  translation: 'zwaar'},
  {english: 'attainability',
  translation: 'haalbaarheid'},
  {english: 'bargain',
  translation: 'overeenkomst'},
  {english: 'begrudgingly',
  translation: 'met tegenzin'},
  {english: 'candidly',
  translation: 'openhartig'},
  {english: 'carpenter',
  translation: 'timmerman'},
  {english: 'comprise',
  translation: 'omvatten'},
  {english: 'conscientious',
  translation: 'gewetensvol'},
  {english: 'contend',
  translation: 'beweren'},
  {english: 'convoluted',
  translation: 'ingewikkeld'},
  {english: 'crave',
  translation: 'snakken naar'},
  {english: 'cringe',
  translation: 'ineenkrimpen'},
  {english: 'curfew',
  translation: 'avondklok'},
  {english: 'deliberately',
  translation: 'opzettelijk'},
  {english: 'dire',
  translation: 'verschrikkelijk'},
  {english: 'disdain',
  translation: 'minachting'},
  {english: 'dismal',
  translation: 'somber'},
  {english: 'dissuade',
  translation: 'afraden'},
  {english: 'divisive',
  translation: 'verdeeldheid zaaien'},
  {english: 'drain',
  translation: 'uitputten'},
  {english: 'dreaded',
  translation: 'gevreesde'},
  {english: 'egregious', translation:'flagrant'},
{english: 'embarking', translation:'aan boord gaan'},
{english: 'emblematic', translation:'symbolisch'},
{english: 'empowered', translation:'gemachtigd'},
{english: 'enamored', translation:'verliefd'},
{english: 'endowed', translation:'begiftigd'},
{english: 'eulogy', translation:'lofrede'},
{english: 'exacerbated', translation:'verergerd'},
{english: 'exhilarating', translation:'opwindend'},
{english: 'fallacy', translation:'drogreden'},
{english: 'fertility', translation:'vruchtbaarheid'},
{english: 'fiend', translation:'duivel'},
{english: 'fiery', translation:'vurig'},
{english: 'finicky', translation:'kieskeurig'},
{english: 'flash in the pan', translation:'vlam in de pan'},
{english: 'foster', translation:'koesteren'},
{english: 'harnessing', translation:'benutten'},
{english: 'havoc', translation:'ravage'},
{english: 'heft', translation:'gewicht'},
{english: 'humility', translation:'nederigheid'},
{english: 'incessant', translation:'onophoudelijk'},
{english: 'inconspicuous', translation:'onopvallend'},
{english: 'inevitable', translation:'onvermijdelijk'},
{english: 'inextricably', translation:'onlosmakelijk'},
{english: 'interlocutor', translation:'gesprekspartner'},
{english: 'lauded', translation:'geprezen'},
{english: 'longevity', translation:'levensduur'},
{english: 'long', translation:'verlangen'},
{english: 'lure', translation:'lokken'},
{english: 'mesmerize', translation:'betoveren'},
{english: 'mimicking', translation:'nabootsen'},
{english: 'misogyny', translation:'vrouwenhaat'},
{english: 'myriad', translation:'veelvoud'},
{english: 'nascent', translation:'ontluikend'},
{english: 'nebulous', translation:'vaag'},
{english: 'nursery', translation:'kinderkamer'},
{english: 'parlance', translation:'spraakgebruik'},
{english: 'pernicious', translation:'verderfelijk'},
{english: 'perusing', translation:'doorlezen'},
{english: 'pervasive', translation:'doordringend'},
{english: 'plethora', translation:'overvloed'},
{english: 'pondered', translation:'overpeinsde'},
{english: 'pram', translation:'kinderwagen'},
{english: 'prevail', translation:'zegevieren'},
{english: 'prevalent', translation:'overheersend'},
{english: 'procrastinating', translation:'uitstellen'},
{english: 'profound', translation:'diepgaand'},
{english: 'proliferated', translation:'verspreidde zich'},
{english: 'prolific', translation:'vruchtbaar'},
{english: 'prolonged', translation:'langdurig'},
{english: 'proprietary', translation:'gepatenteerd'},
{english: 'proverb', translation:'spreekwoord'},
{english: 'provoke', translation:'veroorzaken'},
{english: 'pruning', translation:'snoeien'},
{english: 'pundit', translation:'expert'},
{english: 'punters', translation:'gokkers'},
{english: 'rampant', translation:'ongebreideld'},
{english: 'readily', translation:'gemakkelijk'},
{english: 'refrain', translation:'zich onthouden van'},
{english: 'resilient', translation:'veerkrachtig'},
{english: 'retaliatory', translation:'vergeldingsmaatregelen'},
{english: 'riotous', translation:'losbandig'},
{english: 'run errands', translation:'boodschappen doen'},
{english: 'savoured', translation:'appreciëren'},
{english: 'scoffed', translation:'spotte'},
{english: 'screech', translation:'krijsen'},
{english: 'shear', translation:'snoeien, knippen'},
{english: 'shuns', translation:'schuwt'},
{english: 'soothing', translation:'rustgevend'},
{english: 'spawn', translation:'voortbrengen'},
{english: 'squirm', translation:'kronkelen'},
{english: 'staunch', translation:'standvastig'},
{english: 'stroller', translation:'wandelwagen'},
{english: 'suspense', translation:'spanning'},
{english: 'ubiquitous', translation:'alomtegenwoordig'},
{english: 'unobtrusive', translation:'onopvallend'},
{english: 'vanishing', translation:'verdwijnen'},
{english: 'vast', translation:'enorm'},
{english: 'vigour', translation:'kracht'},
{english: 'virility', translation:'mannelijkheid'},
{english: 'virtuously', translation:'deugdzaam'},
{english: 'whimper', translation:'zacht gejank'},
{english: 'woes', translation:'ellende'},
]